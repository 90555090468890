define('ember-route-history/initializers/inject-route-history', ['exports', 'ember-route-history/services/route-history'], function (exports, _emberRouteHistoryServicesRouteHistory) {
	exports.initialize = initialize;

	function initialize(application) {
		application.register('service:route-history', _emberRouteHistoryServicesRouteHistory['default']);
		application.inject('route', 'routeHistory', 'service:route-history');
	}

	exports['default'] = {
		name: 'injectRouteHistory',
		after: 'store',
		initialize: initialize
	};
});